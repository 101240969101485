<template>
  <div class="promo-winners" id="winners">
    <h2 class="title">
      Победители
    </h2>
    <transition name="fade">
      <table class="promo-winners__table" v-if="showTable">
        <tbody>
          <tr class="promo-winners__table-head">
            <th v-for="item, i in header" :key="'promo-winners-table-head-' + i">
              {{ item }}
            </th>
          </tr>
          <tr v-for="row, i in table" :key="'promo-winners-table-row-' + i">
            <td>
              {{ getFormattedDate(row.created_at) }}
            </td>
            <td>
              {{ row.name }}
            </td>
            <td>
              {{ row.phone }}
            </td>
            <td>
              {{ row.prize }}
            </td>
          </tr>
        </tbody>
      </table>
    </transition>
    <pagination
      :limit="pagination.limit"
      :per-page="pagination.perPage"
      :current="pagination.current"
      :total="pagination.total"
      @change="changePage"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue';

  export default {
    name: 'PromoWinners',
    components: {
      Pagination
    },
    data() {
      return {
        header: ['Дата выигрыша', 'ФИО', 'Телефон', 'Приз'],
        table: [],
        pagination: {
          limit: 4,
          perPage: 0,
          current: 1,
          total: 0,
        },
        showTable: false,
      };
    },
    async created() {
      await this.fetchData();
    },
    methods: {
      getFormattedDate(date) {
        if (!date)
          return '';

        const [year, month, day] = date.slice(0, 10).split('-');

        return `${day}.${month}.${year}`;
      },
      changePage(page) {
        this.pagination.current = page;
        this.fetchData();
      },
      async fetchData() {
        const res = await this.$http.get(`winners?page=${this.pagination.current}`).then(res => res.data);

        this.pagination.perPage = res.meta.per_page;
        this.pagination.total = res.meta.total;
        this.pagination.perPage = res.meta.per_page;

        this.showTable = false;
        this.table = res.data;
        await this.$nextTick();
        this.showTable = true;
      }
    }
  };
</script>

<style scoped lang="scss">
  .promo-winners {
    margin-bottom: 70px;
    padding-top: 56px;

    h2 {
      margin-bottom: 26px;
    }

  }
  .promo-winners__table {
    margin-bottom: 22px;
    width: 100%;

    tr {
      border-radius: 60px;
      overflow: hidden;
    }

    td, th {
      text-align: center;
      border: unset;
      padding: 0;
      vertical-align: middle;
      width: 25%;

      &:first-child {border-top-left-radius: 60px;}
      &:last-child {border-top-right-radius: 60px;}
      &:first-child {border-bottom-left-radius: 60px;}
      &:last-child {border-bottom-right-radius: 60px;}
    }

    tr {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      height: 57px;
      line-height: 57px;

      &:nth-child(n+2) {
        &:nth-child(odd) {
          background: $white;
        }
      }
    }

    th {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      padding-top: 5px;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .promo-winners__table-head {
    background-color: $green;
  }

  @media(max-width: 1150px) {
    .promo-winners {
      h2 {
        margin-bottom: 24px;
        padding-left: 12px;
      }
    }
  }
  @media (max-width: 970px) {
    .promo-winners {
      padding-top: 84px;
    }
  }
  @media(max-width: 768px) {
    .promo-winners__table {
      margin-bottom: 20px;

      th {
        font-size: 14px;
        line-height: 18px;
      }

      td {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
</style>
