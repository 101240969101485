<template>
  <div>
    <b-modal
      id="popit-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon" @click="$bvModal.hide('popit-modal')">
        <img
          src="@/assets/svg/icons/close.svg"
          aria-label="close"
          alt="Закрыть"
        />
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">интерьерный поп-ит</h1>

        <p class="modal-text">
          Играйте в поп-ит и знакомьтесь с готовыми проектами <br />
          ванных комнат от Леруа Мерлен
        </p>

        <button
          class="button button-green"
          @click="$bvModal.hide('popit-modal')"
        >
          начать
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 42px;
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    opacity: 0.8;
  }
}

.button {
  width: 100%;
  max-width: 120px;
  margin-top: 20px;
}
</style>
