export const BACKGROUNDS = [
  {
    id: 1,
    base:  require("@/assets/img/mirror/interior-1.png"),
    figure: require("@/assets/img/mirror/figures/figure-1.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-1.png"),
    finish: require("@/assets/img/mirror/finish/interior-1.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-1.png"),
    width: 492,
    height: 656,
  },
  {
    id: 2,
    base:  require("@/assets/img/mirror/interior-2.png"),
    figure: require("@/assets/img/mirror/figures/figure-2.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-2.png"),
    finish: require("@/assets/img/mirror/finish/interior-2.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-2.png"),
    width: 447,
    height: 597,
  },
  {
    id: 3,
    base:  require("@/assets/img/mirror/interior-3.png"),
    figure: require("@/assets/img/mirror/figures/figure-3.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-3.png"),
    finish: require("@/assets/img/mirror/finish/interior-3.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-3.png"),
    width: 498,
    height: 594,
  },
  {
    id: 4,
    base:  require("@/assets/img/mirror/interior-4.png"),
    figure: require("@/assets/img/mirror/figures/figure-4.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-4.png"),
    finish: require("@/assets/img/mirror/finish/interior-4.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-4.png"),
    width: 489,
    height: 654,
  },
  {
    id: 5,
    base:  require("@/assets/img/mirror/interior-5.png"),
    figure: require("@/assets/img/mirror/figures/figure-5.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-5.png"),
    finish: require("@/assets/img/mirror/finish/interior-5.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-5.png"),
    width: 686,
    height: 606,
  },
  {
    id: 6,
    base:  require("@/assets/img/mirror/interior-6.png"),
    figure: require("@/assets/img/mirror/figures/figure-6.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-6.png"),
    finish: require("@/assets/img/mirror/finish/interior-6.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-6.png"),
    width: 470,
    height: 585,
  },
  {
    id: 7,
    base:  require("@/assets/img/mirror/interior-7.png"),
    figure: require("@/assets/img/mirror/figures/figure-7.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-7.png"),
    finish: require("@/assets/img/mirror/finish/interior-7.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-7.png"),
    width: 487,
    height: 648,
  },
  {
    id: 8,
    base:  require("@/assets/img/mirror/interior-8.png"),
    figure: require("@/assets/img/mirror/figures/figure-8.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-8.png"),
    finish: require("@/assets/img/mirror/finish/interior-8.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-8.png"),
    width: 596,
    height: 684,
  },
  {
    id: 9,
    base:  require("@/assets/img/mirror/interior-9.png"),
    figure: require("@/assets/img/mirror/figures/figure-9.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-9.png"),
    finish: require("@/assets/img/mirror/finish/interior-9.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-9.png"),
    width: 500,
    height: 665,
  },
  {
    id: 10,
    base:  require("@/assets/img/mirror/interior-10.png"),
    figure: require("@/assets/img/mirror/figures/figure-10.png"),
    figureFill: require("@/assets/img/mirror/figures/figure-fill-10.png"),
    finish: require("@/assets/img/mirror/finish/interior-10.png"),
    finishMob: require("@/assets/img/mirror/finish/interior-mob-10.png"),
    width: 472,
    height: 733,
  },
];
