<template>
  <div class="popit-prize" @click="$emit('click')">
    <img
      src="@/assets/png/gift.png"
      srcset="@/assets/png/gift.png, @/assets/png/gift@2x.png 2x"
    />
    <p class="text">ура! подарок!</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.popit-prize {
  height: 75px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
  padding-right: 40px;
  cursor: pointer;
}

.text {
  font-family: $regular;
  font-weight: 700;
  font-size: 21px;
  color: $dark-text;
  border-bottom: 1.5px solid $dark-text;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-left: 15px;
}

img {
  max-width: 155px;
  margin-top: -100px;

  @media (max-width: 576px) {
    max-width: 120px;
    margin-top: -80px;
  }
}
</style>
