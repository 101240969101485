<template>
  <div>
    <b-modal
      id="puzzle-thanks-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <span class="close-icon"
            @click="closeModal">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">спасибо!</h1>

        <p class="modal-text">
          В ближайшее время наши специалисты <br> свяжутся с вами, чтобы уточнить детали <br> по доставке приза.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },

    methods: {
      closeModal(){
        this.$bvModal.hide('puzzle-thanks-modal')
        this.$bvModal.show('puzzle-win-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
.title {
  font-size: 42px;
  text-align: center;
  margin-top: 20px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .8;
  }
}

.modal-text {
  font-size: 24px;
  font-weight: 500 !important;
}
</style>
