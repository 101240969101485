<template>
	<div class="spoiler" v-b-toggle="id">
		<div class="spoiler__header">
			<span class="spoiler__title" v-html="title" />
			<div class="spoiler__icon">
				<img src="@/assets/svg/icons/down-arrow.svg" />
			</div>
		</div>
		<b-collapse :id="id" class="spoiler__inner">
			<div class="spoiler__inner-content">
				<slot></slot>
			</div>
		</b-collapse>
	</div>
</template>

<script>
export default {
	name: 'Spoiler',
	props: {
		title: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			default: '',
		}
	},
};
</script>

<style scoped lang="scss">
	.spoiler {
		background: $green;
		border-radius: 60px;
		margin-bottom: 9px;
		transition: border-radius .3s ease, opacity .2s ease;

		&:focus {
			outline: unset;
		}

		&:hover {
			opacity: .86;
		}

		&.not-collapsed {
			border-radius: 20px;

			.spoiler__icon {
				transform: rotate(180deg);
			}
		}
	}
	.spoiler__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 21px 25px 0 45px;
		cursor: pointer;
	}
	.spoiler__icon {
		transition: transform .3s ease;
	}
	.spoiler__title {
		font-weight: 700;
		font-size: 21px;
		line-height: 26px;
		color: $white;
		margin-bottom: 13px;
	}
	.spoiler__inner {
		transition: .3s;
		padding: 0 25px 0 45px;
	}
	.spoiler__inner-content {
		height: max-content;
		padding-bottom: 10px;

		&::v-deep(p) {
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			color: $white;
		}
	}

	@media (max-width: 489px) {
		.spoiler__header {
			padding: 21px 22px 0 12px;
		}
		.spoiler__title {
			font-size: 18px;
			line-height: 26px;
			margin-bottom: 16px;
		}
		.spoiler {
			&:hover {
				opacity: unset;
			}

			&:active {
				opacity: .86;
			}
		}
		.spoiler__inner-content {
			padding-bottom: 20px;
		}
		.spoiler__inner {
			padding: 0 20px 0 11px;
		}
	}
</style>
