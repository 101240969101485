<template>
  <div class="gift" @click="">
    <div class="gift__icon">
      <img src="@/assets/png/gift.png" alt="gift">
    </div>
    <div class="gift__texts">
      <p class="gift__name">
        {{ name }}
      </p>
      <p class="gift__desc">
        {{ desc }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Gift',
    props: {
      name: {
        type: String,
        default: '',
      },
      desc: {
        type: String,
        default: '',
      },
    },
    methods: {
      click() {
        this.$emit('click');
      }
    }
  };
</script>

<style scoped lang="scss">
  .gift {
    position: relative;
    background: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    padding: 23px 10px 20px 88px;

    &:hover {
      .gift__icon img {
        transform: rotate(9.87deg);
      }
    }
  }
  .gift__icon {
    position: absolute;
    left: -14px;
    top: -14px;

    img {
      transition: .3s ease;
      width: 85px;
    }

    &:after {
      content: "";
      position: absolute;
      background: #44423F;
      opacity: 0.3;
      filter: blur(10px);
      width: 80%;
      height: 9px;
      bottom: -9px;
      left: 57%;
      transform: translateX(-50%);
    }
  }
  .gift__name {
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
    text-transform: uppercase;
    color: $dark-text;
    margin-bottom: 6px;
  }
  .gift__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $dark-text;
    margin-bottom: 0;
  }
  @media (max-width: 800px) {
    .gift {
      display: flex;
      align-items: center;
      padding: 16px 16px 16px 10px;
    }
    .gift__icon {
      position: relative;
      left: unset;
      top: unset;
      margin-right: 10px;

      img {
        width: 73px;
      }
    }
    .gift__name {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
</style>
