<template>
  <div :class="['mirror-game', { 'm-hide': isComplete }]" :style="sizes">
    <div :class="['mirror-game__complete', { 'm-hide': !isComplete }]">
      <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.5 0C12.7805 0 0 12.7805 0 28.5C0 44.2195 12.7805 57 28.5 57C44.2195 57 57 44.2195 57 28.5C57 12.7805 44.2195 0 28.5 0Z"
          fill="#5AB031"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.2824 18.8924C42.9726 19.5826 42.9726 20.7182 42.2824 21.4084L25.5832 38.1076C25.2381 38.4527 24.7816 38.6309 24.3252 38.6309C23.8687 38.6309 23.4123 38.4527 23.0672 38.1076L14.7176 29.758C14.0273 29.0678 14.0273 27.9322 14.7176 27.242C15.4078 26.5518 16.5433 26.5518 17.2336 27.242L24.3252 34.3336L39.7664 18.8924C40.4566 18.191 41.5922 18.191 42.2824 18.8924Z"
          fill="white"
        />
      </svg>
    </div>
    <scratch-card
      :key="renderCount"
      :cardWidth="width"
      :cardHeight="height"
      :finishPercent="finishPercent"
      :imageUrl="frontImg"
      @complete="$emit('complete')"
    >
      <div class="mirror-game__back" :style="selectedBackground"></div>
    </scratch-card>
  </div>
</template>

<script>
import ScratchCard from "vue-scratchcard";

export default {
  name: "MirrorGame",
  components: {
    ScratchCard,
  },
  props: {
    frontImg: {
      type: String,
      required: true,
    },
    backImg: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 444,
    },
    height: {
      type: Number,
      default: 609,
    },
    renderCount: {
      type: Number,
      default: 0,
    },
    finishPercent: {
      type: Number,
      default: 40,
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedBackground() {
      return {
        backgroundImage: `url(${this.backImg})`,
      };
    },
    sizes() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.mirror-game {
  position: relative;
  opacity: 1;
  transition: opacity 1s;

  &.m-hide {
    opacity: 0;
    transition: opacity 1s 3s;
  }

  &__back {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__complete {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    z-index: 1;
    transition: opacity 0.5s;

    &.m-hide {
      opacity: 0;
    }
  }
}
</style>
