export const TEST_RESULT_TYPES = ['auditory' ,'visual' ,'kinesthetic' ,'digital'];

export const TEST_QUESTIONS = [
	{
		question: 'Чему вы больше поверите?',
		answers: [
			{
				text: 'тому, что увидите своими глазами',
				code: 'visual',
			},
			{
				text: 'фактам',
				code: 'digital',
			},
			{
				text: 'собственным чувствам',
				code: 'kinesthetic',
			},
			{
				text: 'тому, в чем есть для вас смысл',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Чему вы учитесь чаще всего?',
		answers: [
			{
				text: 'понимать какие-то вещи',
				code: 'digital',
			},
			{
				text: 'делать какие-то вещи',
				code: 'kinesthetic',
			},
			{
				text: 'слушать новое',
				code: 'auditory',
			},
			{
				text: 'видеть новые возможности',
				code: 'visual',
			},
		]
	},
	{
		question: 'Как вы принимаете важные решения?',
		answers: [
			{
				text: 'Основываясь на своих ощущениях',
				code: 'kinesthetic',
			},
			{
				text: 'Если будет звучать для меня убедительно',
				code: 'auditory',
			},
			{
				text: 'Опираясь на собственную точку зрения',
				code: 'visual',
			},
			{
				text: 'Опираюсь на факты и логику',
				code: 'digital',
			},
		]
	},
	{
		question: 'Какой человек скорее всего окажет на вас влияние?',
		answers: [
			{
				text: 'У кого приятный голос',
				code: 'auditory',
			},
			{
				text: 'Кто хорошо выглядит',
				code: 'visual',
			},
			{
				text: 'Кто говорит разумные вещи',
				code: 'digital',
			},
			{
				text: 'Кто пробуждает у меня хорошие чувства',
				code: 'kinesthetic',
			},
		]
	},
	{
		question: 'Какое из утверждений подходит вам больше всего?',
		answers: [
			{
				text: 'мне очень легко понять смысл новых фактов и данных',
				code: 'digital',
			},
			{
				text: 'я всегда обращаю внимание на звуки окружающей действительности',
				code: 'auditory',
			},
			{
				text: 'я хорошо чувствую одежду, облегающую мое тело',
				code: 'kinesthetic',
			},
			{
				text: 'я легко замечаю все краски мира',
				code: 'visual',
			},
		]
	},
	{
		question: 'Представляя своего друга, что вы вспомните в первую очередь?',
		answers: [
			{
				text: 'Как он выглядит',
				code: 'visual',
			},
			{
				text: 'Как звучит его голос',
				code: 'auditory',
			},
			{
				text: 'Как он говорит',
				code: 'digital',
			},
			{
				text: 'Свои ощущения по отношению к нему',
				code: 'kinesthetic',
			},
		]
	},
	{
		question: 'Что для вас предпочтительнее?',
		answers: [
			{
				text: 'услышать факты, о которых человек знает',
				code: 'digital',
			},
			{
				text: 'увидеть картины, которые человек нарисовал',
				code: 'visual',
			},
			{
				text: 'узнать о чувствах человека',
				code: 'kinesthetic',
			},
			{
				text: 'послушать звучание голоса, звуки происходящих событий',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Как окружающие смогут узнать о ваших делах?',
		answers: [
			{
				text: 'по моим чувствам',
				code: 'kinesthetic',
			},
			{
				text: 'по моему внешнему виду',
				code: 'visual',
			},
			{
				text: 'им следует послушать, как я говорю',
				code: 'digital',
			},
			{
				text: 'по интонациям моего голоса',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Какое утверждение о вас наиболее верно?',
		answers: [
			{
				text: 'обычно я хорошо чувствую настроения людей',
				code: 'kinesthetic',
			},
			{
				text: 'я могу нарисовать лица, различные подробности того, что я видел',
				code: 'visual',
			},
			{
				text: 'я знаю, что именно думают близкие люди по поводу тех или иных вопросов',
				code: 'digital',
			},
			{
				text: 'я хорошо различаю интонацию голосов моих друзей',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Что вам легче всего сделать из нижеперечисленного?',
		answers: [
			{
				text: 'найти идеальную для себя громкость звучания магнитофона, телевизора',
				code: 'auditory',
			},
			{
				text: 'подобрать наиболее разумные, убедительные аргументы, относящиеся к интересующему вас предмету',
				code: 'digital',
			},
			{
				text: 'выбрать наиболее удобную мебель',
				code: 'kinesthetic',
			},
			{
				text: 'подобрать для одежды, интерьера и др. богатые цветовые комбинации',
				code: 'visual',
			},
		]
	},
];

export const TEST_RESULTS = {
	'auditory': {
		title: 'У вас аудиальный тип репрезентативной системы',
		text: 'Для вас важен ритм и гармония. Только от вас можно услышать: «Как звучат здесь краски! Какой удивительный ритм у этой графики!». Вам важно, как это «звучит» и отражается во внутреннем мире. В тоне и интонациях для вас сконцентрирован смысл того, о чем вам рассказывают. Не та интонация или ритм, и вы чувствуете дискомфорт. Обустраивая дом, обратите внимание на то, какая в нем акустика. Возможно, вам понадобится установить дополнительную звукоизоляцию, чтобы не слышать соседей или не пугать их мощной акустической системой.',
	},
	'visual': {
		title: 'У вас визуальный тип репрезентативной системы',
		text: 'Визуал воспринимает мир глазами. Для вас очень важно, как «это» выглядит. И неважно, о чем идет речь, о собственном внешнем виде, об интерьере помещения, в котором вы находитесь, или сервировке стола. Главное, чтобы вы воспринимали объект как красивый и гармоничный лично для вас. Обустройство дома может стать для вас увлекательным квестом. Чтобы пройти его с удовольствием, подберите основную гамму цветов, которые станут базой в интерьере. Так вы сможете сделать его максимально вариативным, добавлять отдельные акценты или детали, заменять их, чтобы обстановка радовала глаз как можно дольше.',
	},
	'kinesthetic': {
		title: 'У вас кинестетический тип репрезентативной системы',
		text: 'Для кинестетика самое главное — это удобство. Телесные ощущения комфорта и легкости вызывают приятные эмоции, ощущения удовлетворения и расслабленности. Ваше удовольствие и радость в тактильности. Дотрагиваетесь ли вы до теплого отполированного дерева или приятно шершавой керамики, если прикосновение вам приятно, то внешний вид вторичен. При обустройстве дома уделяйте внимание разным фактурам и текстурам в отделочных материалах, текстиле, аксессуарах и декоре. Обратите внимание на неочевидные решения. В вашем доме текстурный ламинат может оказаться на стенах, а искусственный газон на балконе.',
	},
	'digital': {
		title: 'У вас дигитальный тип репрезентативной системы',
		text: 'Люди с дигитальным восприятием мира довольно редки. Это строгие логики, воспринимающие мир через причинно-следственные связи. Вас никогда не убедят в своей правоте, если не выстроят логическую цепочку. «Это зеркало будет висеть здесь, потому что правильно взглянуть на себя перед выходом из дома». — такой логичный аргумент будет вами принят. Но если с вами начнут разговор об акценте в интерьере, то вы будете сбиты с толку. Не расстраивайтесь! Вы с размахом можете реализовать себя в организации порядка: шкафы и шкафчики, тумбы, стеллажи, корзины, контейнеры и ящички. А самым продвинутым можно переходить к проектированию инженерных систем. Только вы заранее знаете, где должна быть розетка или зачем нужен датчик протечек.',
	},
};

export const RECOMMENDATIONS_BY_TYPE = {
	visual: {
		title: 'ВИЗУАЛ',
		slides: [
			{
				img: 'visual-1',
				title: 'Скандинавский стиль',
				text: 'Сочетание плитки с узором травы и приглушенного зеленого цвета успокоит и расслабит человека, любящего наблюдать за природой. В этой ванной всего два цвета, которые используются для зонирования. Акцентная стена за зеркалом объединяет цветовую гамму. Общий пастельный тон и природные мотивы приятны глазу. Основной верхний свет дополняется зеркалом с подсветкой, что позволяет регулировать интенсивность освещения. Визуал в такой ванной ощутит прилив приятных эмоций.',
			},
			{
				img: 'visual-2',
				title: 'Скандинавский стиль',
				text: 'Белый цвет сантехники и тумбы под раковиной выбран не случайно. Это самый нейтральный, но богатый на изменения вариант. Как чистый лист, он может успокаивать своим почти идеальным монохромом, слегка разбавленным природными мотивами и естественными оттенками дерева. А может взбодрить, если добавить ярких акцентов, таких как текстиль или аксессуары. А поскольку аксессуары довольно легко заменить, визуал может легко менять цвет и акцентные пятна в таком пространстве. Оно ему не надоест.',
			},
			{
				img: 'visual-3',
				title: 'Дизайнерский стиль',
				text: 'Завораживающий глубиной изумрудный цвет подчеркивает строгую геометрию зеркала и душевой кабины. Создает объем, в котором игра света и тени как будто переносят в отдельную комнату. Черный цвет в качестве акцента используется для отдельных элементов, достаточно заметных, чтобы акцент не размывался, но при этом не слишком объемных, чтобы не сделать помещение слишком мрачным. Такой прием для визуала очень органичен, ведь пространство зонируется с помощью цвета.',
			},
		]
	},
	kinesthetic: {
		title: 'КИНЕСТЕТИК',
		slides: [
			{
				img: 'kinesthetic-1',
				title: 'Скандинавский стиль',
				text: 'Это мечта кинестетика! Возможность порадовать свое тело, расслабиться, получить эффект SPA у себя дома не может не порадовать. Наличие двойного душа с разной высотой леек и разными режимами полива, которые позволяют регулировать силу струи и угол попадания на кожу, угловая полка, удобно размещенная рядом с душем. Протяни руку и хоть с закрытыми глазами найдешь нужный флакон. В таких ванных органично сочетаются разные текстуры: гладкой сантехники, фактурной плитки, натурального пропитанного маслом дерева.',
			},
			{
				img: 'kinesthetic-2',
				title: 'Скандинавский стиль',
				text: 'В этом проекте минимальное количество гладких глянцевых поверхностей, которые бликуют и могут создавать ощущение стерильного холода. Покрашенная матовой краской стена, матовые фасады мебели, прикосновение к которым оставляет легчайшие, почти неощутимые мурашки на кончиках пальцев. Даже душевая перегородка с матированным стеклом. Это пространство, в котором прикосновение к каждой поверхности словно оставляет некую недосказанность, переключая сознание с дневных хлопот на умиротворяющую простоту ощущений.',
			},
			{
				img: 'kinesthetic-3',
				title: 'Природный стиль',
				text: 'Для ощущений кинестетика здесь раздолье: холодный глянец на стене, тепло деревянной текстуры в душевом уголке, матовая плитка, объемная стена с отделкой рейками. В таком пространстве ощущение близости природы, отдыха. В такой ванной душ с функцией тропического ливня, из которого льются потоки воды слегка покалывая кожу, может погрузить кинестетика в состояние легкой эйфории. Самые чутки кинестеты могут дополнить пространство мягким текстилем: коврики, полотенца, банные халаты, — это то, что обволакивает после водных процедур и позволяет сохранить тепло и расслабленность.',
			},
		]
	},
	auditory: {
		title: 'АУДИАЛ',
		slides: [
			{
				img: 'auditory-1',
				title: 'Неоклассика',
				text: 'Плитка с геометрическим узором задает определенный ритм. Свободное пространство между геометрическими фигурами позволяет аудиалу «слышать свою мелодию». Акустика в ванной для аудиала играет немаловажную роль. В этом проекте абсолютно гладкая плитка на всех стенах. Отсутствие лишних элементов декора и мебели, подвесная тумба под раковину оставляют много свободного пространства. Это позволяет звуку отражаться и быть практически объемным.   ',
			},
			{
				img: 'auditory-2',
				title: 'Неоклассика',
				text: 'Легкость и воздушность интерьера ванной комнаты, без перегруженности деталями, без резких контрастов позволит расслабиться, ощутить себя дома, в своем мире. Добавьте сюда умную колонку, чтобы включать звуки дождя или шум водопада, когда принимаете душ или звуки леса и пение птиц по утрам, когда готовитесь к новому дню или инструментальный чилаут, пока принимаете ванну, и вы удивитесь, насколько в такой ванной объемный и почти ощутимый звук.',
			},
			{
				img: 'auditory-3',
				title: 'Неоклассика',
				text: 'Спокойный, ровный тон в интерьере позволяет услышать себя, свои мысли и желания. Отсутствие абстракций в отделке пола и стен, повторяющийся паттерн на стене за зеркалом и на акцентной стене из реек формируют определенный ритм. Стенки душевого уголка создают дополнительный контур, который усиливает восприятие слуха. В нем проще спокойно расслабиться, закрыть глаза, прислушаться к шуму падающей воды, почувствовать ее ритм, — вот, что сделает аудиал в ванной комнате в изящных спокойных тонах. И будет вполне счастлив от возможности отдохнуть. ',
			},
		]
	},
	digital: {
		title: 'ДИГИТАЛ',
		slides: [
			{
				img: 'digital-1',
				title: 'Природный стиль',
				text: 'Изысканная простота и функциональность полностью отвечают запросу человека-логика. Гармонично влияют на его эмоции, внушают спокойствие и чувство порядка. Продуманное хранение, минимальное количество деталей. В таком интерьере практически невозможно устроить бардак, что дигитала более, чем радует. Пустой пространство под полкой с раковиной позволяет легко мыть полы. Угловые раздвижные дверцы обеспечивают свободный проход даже в условиях довольно небольшого помещения. Почти невидимая дверца шахты — это отдельный вид эстетического удовольствия.',
			},
			{
				img: 'digital-2',
				title: 'Шарм',
				text: 'Логически выверенное и строго организованное пространство сообщает: «Все хорошо, все правильно, все на своих местах!». Это действует успокаивающе, позволяет убрать лишнюю тревогу, снижает уровень стресса. Если ваше сердце стало биться чуть быстрее при виде сушильной машины и организованной постирочной зоны в санузле, которая абсолютно невидима при закрытых дверцах, вы однозначно дигитал. Такие решения, как и зеркало с подсветкой и смеситель с термодатчиками или сенсорами движения — это абсолютно ваши решения. Можно биться об заклад, что даже в шкафу будут отдельные контейнеры для хранения по категориям вещей. Ведь порядок = удовольствие.',
			},
			{
				img: 'digital-3',
				title: 'Неоклассика',
				text: 'Ясность без неуместных контрастов и резких переходов цвета воспринимается как продуманное и безопасное пространство. Само ощущение легкого перехода тонов одной гаммы создает ощущение покоя и удовлетворения. Единая цветовая гамма, упорядоченное пространство, отсутствие мелких ненужных деталей, за которые цепляется глаз, — это то, что дигитала расслабляет. Грамотное разноуровневое освещение с четкой геометрией позволяют не только создавать глубину пространству, но и избегать излишне яркого верхнего света, когда хочется теплого рассеянного свечения, раздвигающего границы полутьмы.',
			},
		]
	},
};
