<template>
    <div class="pagination">
        <b-pagination
            :limit="limit"
            hide-ellipsis
            :total-rows="total"
            :value="current"
            :per-page="perPage"
            first-number
            @input="changePage"
        >
            <template #prev-text>
                <div class="pagination__arrow pagination__arrow--prev" />
            </template>
            <template #next-text>
                <div class="pagination__arrow pagination__arrow--next" />
            </template>
        </b-pagination>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        limit: {
            type: Number,
            default: 4,
        },
        perPage: {
            type: Number,
            default: 6,
        },
        current: {
            type: Number,
            default: 1,
        },
        total: {
            type: Number,
            default: 100,
        }
    },
    methods: {
        changePage(page) {
            this.$emit('change', page);
        }
    }
}
</script>

<style scoped lang="scss">
    .pagination {
        &::v-deep(.b-pagination) {
            position: relative;
            background-color: $white;
            align-items: center;
            padding: 0 38px;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            height: 44px;
            border-radius: 0;
        }
        &::v-deep(.page-item) {
            &:last-child {
                display: none;
            }

            &:nth-child(1) {
                position: absolute;
                left: -22px;
            }

            &:nth-last-child(2) {
                position: absolute;
                right: -22px;
            }
        }

        &::v-deep(.page-link), &::v-deep(.page-item.disabled .page-link) {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #061E41;
            background-color: unset;
            border: unset;
            transition: .2s ease;
            padding: 8px 10px;
            transition: .6s ease;

            &:hover {
                color: $light-green;
                opacity: .86;
            }

            &:focus {
                box-shadow: unset;
                opacity: .86;
            }
        }
        &::v-deep(.page-item) {
            &.active .page-link {
                background-color: unset;
                color: $light-green;
            }
        }
    }
    .pagination__arrow {
        background-color: $light-green;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        background-repeat: no-repeat;
        background-position: center center;

        &--next {
            background-image: url(@/assets/svg/icons/down-arrow.svg);
            transform: rotate(-90deg);
        }
        &--prev {
            background-image: url(@/assets/svg/icons/down-arrow.svg);
            transform: rotate(90deg);
        }
    }
</style>
