import { render, staticRenderFns } from "./PromoMechanics.vue?vue&type=template&id=057826ac&scoped=true&"
import script from "./PromoMechanics.vue?vue&type=script&lang=js&"
export * from "./PromoMechanics.vue?vue&type=script&lang=js&"
import style0 from "./PromoMechanics.vue?vue&type=style&index=0&id=057826ac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057826ac",
  null
  
)

export default component.exports