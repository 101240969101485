<template>
  <div class="banner-with-logo">
    <transition name="fade">
      <h2 v-if="showDefault">
        <slot />
      </h2>
    </transition>
    <transition name="fade">
      <h2 v-if="!showDefault">
        <slot name="second" />
      </h2>
    </transition>
    <img src="@/assets/svg/icons/banner-logo.svg" alt="">
  </div>
</template>

<script>
  export default {
    name: 'BannerWithLogo',
    props: {
      animated: {
        type: Boolean,
        default: false,
      },
      timeout: {
        type: Number,
        default: 3000000
      }
    },
    data() {
      return {
        showDefault: true,
        interval: null
      };
    },
    methods: {
      initIterval() {
        this.interval = setInterval(() => {
          this.showDefault = !this.showDefault;
        }, this.timeout);
      },
      stopInterval() {
        clearInterval(this.interval);
      }
    },
    mounted() {
      if (this.animated)
        this.initIterval();
    },
    destroyed() {
      this.stopInterval();
    },
  };
</script>

<style scoped lang="scss">
  .banner-with-logo {
    position: relative;
    background-color: $green;
    padding: 14px 32px 120px 33px;
    max-width: 577px;
    min-height: 316px;

    h2 {
      position: absolute;
      left: 33px;
      top: 14px;
      font-family: $din-display;
      font-weight: 700;
      font-size: 54px;
      line-height: 62px;
      color: $white;

      &::v-deep(span) {
        position: relative;
        padding: 0 4px;
        margin-left: 4px;

        & > span {
          color: $green;
          z-index: 2;
          padding: 0;
          margin-left: 0;

          &:before {
            display: none;
          }
        }

        &:before {
          content: "";
          position: absolute;
          background-color: $white;
          left: 0;
          top: 3px;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }

    img {
      position: absolute;
      margin-left: auto;
      height: 77px;
      bottom: 0;
      right: 50px;
    }
  }
  @media (max-width: 768px) {
    .banner-with-logo {
      min-height: 263px;

      h2 {
        font-size: 40px;
        line-height: 49px;
      }

    }
  }
  @media (max-width: 489px) {
    .banner-with-logo {
      padding: 13px 31px 90px 31px;

      h2 {
        left: 31px;
        top: 13px;

        &::v-deep(span) {
          margin-left: 3px;
        }
      }

      img {
        height: 63px;
        right: 38px;
      }
    }
  }
</style>
