import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import Promo from '../views/Promo.vue'
import Popit from '../views/Popit.vue'
import Mirror from '../views/Mirror.vue'
import PsychologistOpinion from '../views/PsychologistOpinion.vue'

const Puzzle = () => import('../views/Puzzle.vue')
const PuzzleHard = () => import('../views/PuzzleHard.vue')
const PuzzleMedium = () => import('../views/PuzzleMedium.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/promo',
    name: 'promo',
    component: Promo
  },
  {
    path: '/popit',
    name: 'popit',
    component: Popit
  },
  {
    path: '/mirror',
    name: 'mirror',
    component: Mirror
  },
  {
    path: '/puzzle',
    name: 'puzzle',
    component: Puzzle
  },
  {
    path: '/puzzle-medium',
    name: 'puzzle-medium',
    component: PuzzleMedium
  },
  {
    path: '/puzzle-hard',
    name: 'puzzle-hard',
    component: PuzzleHard
  },
  {
    path: '/psychologist-page',
    name: 'psychologist-page',
    component: PsychologistOpinion
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
