export default function(instance) {
  return {
    getPlayerInfo(payload) {
      return instance.post('/attempts', payload);
    },
    getPrize(payload) {
      return instance.post('/winners', payload);
    }
  };
}
