<template>
  <div id="app">
    <Sidebar />

    <router-view />

    <Plug />

    <Popit />

    <Puzzle />

    <Thanks />

    <GetPrize />

    <PopitWin />

    <GetPrizePuzzle />

    <PuzzleThanks />

    <GetPrizeMirror/>

    <MirrorWin />

    <Mirror />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Plug from "@/components/modals/Plug.vue";
import Popit from "@/components/modals/Popit.vue";
import Puzzle from "@/components/modals/Puzzle.vue";
import Mirror from "@/components/modals/Mirror.vue";
import Thanks from "@/components/modals/Thanks.vue";
import GetPrize from "@/components/modals/GetPrize.vue";
import PopitWin from "@/components/modals/PopitWin.vue";
import MirrorWin from "@/components/modals/MirrorWin.vue";
import PuzzleThanks from "@/components/modals/PuzzleThanks.vue"
import GetPrizePuzzle from "@/components/modals/GetPrizePuzzle.vue";
import GetPrizeMirror from "@/components/modals/GetPrizeMirror.vue";

export default {
  components: {
    Plug,
    Popit,
    Mirror,
    Thanks,
    Puzzle,
    Sidebar,
    GetPrize,
    PopitWin,
    MirrorWin,
    PuzzleThanks,
    GetPrizePuzzle,
    GetPrizeMirror,
  },
};
</script>

<style lang="scss"></style>
